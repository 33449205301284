import { SliderContainer } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import React from 'react';

import * as S from './styles';

export type SlideProps = {
  image: string | React.ReactNode;
  imageAlt: string;
  title: string;
  copy: string;
  subtitle: string;
};

export interface TestimonialSectionProps {
  headline: string;
  slides: SlideProps[];
}

export const TestimonialSection: React.FC<TestimonialSectionProps> = ({
  headline,
  slides,
}) => (
  <SectionWrapper>
    <S.Container>
      <SliderContainer title={headline} slideData={slides} />
    </S.Container>
  </SectionWrapper>
);

export default TestimonialSection;
