import TestimonialSection from 'components/Homepage/TestimonialSection';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React from 'react';

import { Slide } from '../../../utils/types';

export interface TestimonialContainerProps {
  content: {
    headline: string;
    listOfContentWithImages: Slide[];
  };
}

export const TestimonialContainer: React.FC<TestimonialContainerProps> = ({
  content,
}) => {
  if (!content) {
    return null;
  }

  const { headline, listOfContentWithImages } = content;

  const formattedListOfContent = listOfContentWithImages.map((item) => ({
    title: item.heading,
    copy: item.description.description,
    image: (
      <Img
        image={item.image.gatsbyImageData}
        style={{ width: '100%' }}
        alt=""
      />
    ),
    imageAlt: item.image.description || '',
    subtitle: item.subheading,
  }));

  return (
    <TestimonialSection headline={headline} slides={formattedListOfContent} />
  );
};

export default TestimonialContainer;
